.add-resource .rdw-editor-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
}
.add-resource .rdw-editor-main {
    background-color: #fff;
}
.add-resource .DraftEditor-editorContainer {
    padding: 0 1rem;
}
.add-resource .draft-editor-wrapper {
    border: 1px solid #ccc;
}

.file-container {
    display: flex;
    justify-content: space-between;
    border: 2px solid;
    border-style: dashed;
    margin-bottom: 20px;
    border-radius: 5px;
}

.aside, 
.dropzone {
    padding: 10px;
    width: 100%;
}
.dropzone {
    cursor: pointer;
}
.aside>p {
    color: blue
}

.dropzone {
    border-right: 2px solid;
    border-right-style: dashed;
}
input {
    width: 100%;
    padding: 10px;
    font-weight: 900;
    font-size: 18px;
    margin: 10px 0;
    width: -webkit-fill-available;
}